import { Component } from 'react'
import { connect } from 'react-redux'

// Styling Components
import { Divider, Grid, GridRow, GridColumn, Header, Icon } from 'semantic-ui-react'

// Styling
import './LandingPage.css'

class LandingPage extends Component {
  render () {
    return (
      <div className='landing-page'>
          <Grid columns={2} divided stackable>
            <GridRow>
              <GridColumn className='landing-container'>
                <div className='landing-logo-container'>
                  <div className='landing-logo-spacer'></div>
                  <img className='landing-logo' src='/logo-white.svg' alt={''} />
                </div>
              </GridColumn>
              <GridColumn className='landing-container'>
                <div className='landing-about-container'>
                  <div className="landing-about">
                    <p className="landing-about-text">
                      a quantitative trading fund utilizing time tested financial strategies adapted to volatile crypto markets.
                    </p>
                    <div className="landing-contact-container">
                      <Icon className='landing-mail-icon' name='mail outline' size='small'/>
                        <a className="landing-contact" href="mailto: contact@rhincodon.io">contact@rhincodon.io</a>
                    </div>
                  </div>
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
          <div className='footer'>
            <div className='disclaimer'>
              <h6>
              The materials on this website are for illustration and discussion purposes only and do not constitute an offering. An offering may be made only by delivery of a confidential offering memorandum to appropriate investors. Past performance is no guarantee of future results.
              </h6>
            </div>
          </div>
      </div>
    )
  }
}

LandingPage.propTypes = {}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage)
